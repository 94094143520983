import { z } from 'zod';

import {
  DriveTrain,
  ExternalAgentCaseStatus,
  ExternalAgentCaseStatusText,
  RsaOrTowServiceInvoicingSchema,
} from './ExternalAgent';

const SituationType = {
  1: 'Polttoaine loppu',
  2: 'Väärä tankkaus',
  3: 'Itseaiheutettu',
  4: 'Rengasrikko',
  5: 'Liikennevahinko',
  6: 'Ajoneuvo ei käynnisty (akku loppu)',
  7: 'Ajoneuvo ei käynnisty (muu)',
  8: 'Tekninen vika (sammui ajosta)',
  9: 'Tekninen vika (moottori)',
  10: 'Tekninen vika (vaihteisto)',
  11: 'Tekninen vika (voimansiirto)',
  12: 'Tekninen vika (alustanosat)',
  13: 'Tekninen vika (jarrut)',
  14: 'Tekninen vika (korinosat - ikkunat, ovet, lukot)',
  15: 'Uloslukitus',
  16: 'Häiriövalo keltainen (oireeton)',
  17: 'Aiheeton puhelu',
  18: 'Kaskovahinko',
  19: 'Hirvieläinvahinko',
  20: 'Jäätynyt latauskaapeli',
} as const;
type SituationType = (typeof SituationType)[keyof typeof SituationType];

export const HertzOrderFormCaseCreateEvent =
  'HertzOrderForm.TaskCard.Create' as const;
export const HertzOrderFormCaseUpdateEvent =
  'HertzOrderForm.TaskCard.Update' as const;
export const HertzOrderFormCaseCancelEvent =
  'HertzOrderForm.TaskCard.Cancel' as const;

const BaseRequestSchema = z.object({
  caseId: z.string(),
  assistanceCaseNumber: z.string().optional(),
  client: z.object({
    name: z.string(),
    phoneNumber: z.string(),
  }),
  vehicle: z.object({
    registrationPlate: z.string().min(1),
    make: z.string().min(1),
    model: z.string().min(1),
    mileage: z.coerce.number().min(1),
    driveTrain: z.nativeEnum(DriveTrain),
  }),
  location: z.object({
    city: z.string().min(1),
    address: z.string().min(1),
    country: z.object({
      name: z.string().min(1),
      alfa2: z.string().min(2).max(2),
      alfa3: z.string().min(3).max(3),
    }),
  }),
  situation: z.object({
    situationType: z.object({
      id: z.string(),
      type: z.nativeEnum(SituationType),
    }),
    animalsOnBoard: z.boolean(),
    hasTrailer: z.boolean(),
    personCount: z.coerce.number(),
  }),
  comments: z.string().optional(),
  creator: z.object({
    email: z.string(),
    name: z.string(),
  }),
});
export type HertzOrderFormBase = z.infer<typeof BaseRequestSchema>;

export const HertzOrderFormRequestSchema = BaseRequestSchema.extend({});

export type HertzOrderForm = z.infer<typeof HertzOrderFormRequestSchema>;

const HertzOrderFormCaseUpdateBaseSchema = z.object({
  id: z.string(),
  assistanceCaseNumber: z.string(),
  status: z.nativeEnum(ExternalAgentCaseStatus),
  statusText: z.nativeEnum(ExternalAgentCaseStatusText),
  caseId: z.string(),
  fulfilledServices: z
    .array(
      z.discriminatedUnion('serviceType', [RsaOrTowServiceInvoicingSchema])
    )
    .optional(),
});

// TODO: extend in the future if needed as done in GarageForm
export const HertzOrderFormCaseUpdateSchema =
  HertzOrderFormCaseUpdateBaseSchema.extend({});

export type HertzOrderFormCaseUpdate = z.infer<
  typeof HertzOrderFormCaseUpdateSchema
>;
